<template>
    <div class="container">

        <div class="row py-4 px-3">

            <div class="col-lg text-center d-flex justify-content-center ">
                <img src="@/assets/logos/logoInnovatec.jpg" alt="" class="logoinnovatec rounded-2">

            </div>

            <div class="col parrafo-inicial rounded-3">

                <div class="row p-4 pb-2">
                    <h4 class="fw-bold p-0">DESCRIPCIÓN</h4>
                </div>

                <div class="row px-4 pb-3"> Los grupos de investigación del SENA se enmarcan en la investigación
                    aplicada, lo que
                    significa que los
                    procesos de enseñanza y aprendizaje, que se hacen entre los instructores y los aprendices, están
                    encaminados a transformar el entorno productivo mediante la utilización de nuevas técnicas, insumos,
                    materiales, maquinarias y cambios en los procesos. Como resultado de las políticas de investigación
                    adoptadas por el SENA, en febrero de 2013 se conforma el Grupo de Investigación, Desarrollo
                    Tecnológico
                    e Innovación CTPI SENA Cauca (INNOVATEC CTPI), cuya orientación es la promoción de las capacidades
                    investigativas en la comunidad científica y académica del centro de formación, a través del
                    desarrollo
                    de procesos teórico-prácticos de formación profesional integral, la aplicación de las competencias
                    laborales de los aprendices y el aporte a las necesidades tecnológicas de las empresas afines a las
                    áreas estratégicas del centro, para generar la habilidad de trabajo en equipo, impulsar la formación
                    permanente y facilitar la interacción entre lo académico y lo práctico a través de la investigación
                    interdisciplinaria.
                </div>
            </div>
        </div>


        <div class="container table-responsive pt-4 px-2">
            <table class="table table-bordered table-hover border rounded-2">

                <tbody>
                    <tr class="hover-header">
                        <th class="">Código MinCiencias</th>
                        <td>COL0141469

                        </td>

                    </tr>
                    <tr>
                        <th>Fecha de Creación</th>
                        <td>2013-2

                        </td>

                    </tr>
                    <tr>
                        <th>Nombre del Grupo</th>
                        <td>INNOVATEC CTPI

                        </td>

                    </tr>
                    <tr>
                        <th>Líder del Grupo</th>
                        <td>Wilson Yecit Ortíz Sánchez

                        </td>

                    </tr>
                    <tr>
                        <th>Área de Conocimiento</th>
                        <td>Ingeniería y Tecnología</td>
                    </tr>
                    <tr>
                        <th>Institución</th>
                        <td>Servicio Nacional de Aprendizaje - SENA</td>

                    </tr>
                    <tr>
                        <th>Centro de Formación</th>
                        <td>Centro de Teleinformática y Producción Industrial</td>

                    </tr>
                    <tr>
                        <th> Ciudad y Departamento</th>
                        <td>Popayán - Cauca</td>
                    </tr>
                    <tr>
                        <th>GrupLAC</th>
                        <td> <a
                                href="">https://scienti.minciencias.gov.co/gruplac/jsp/visualiza/visualizagr.jsp?nro=00000000014350</a>
                        </td>
                    </tr>



                </tbody>
            </table>
        </div>


        <hr>




        <section>
            <div class="block py-5">



                <div id="parallax-world-of-ugg">

                    <section>
                        <div class="parallax-two">
                            <h2>Lineas de Investigacion</h2>
                        </div>
                    </section>

                </div>





                <h5 class="mt-3">Las actividades realizadas por el grupo INNOVATEC CTPI se enmarcan en 3 líneas de
                    investigación:</h5>


                <div class="accordion accordion-flush border my-4" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed bg-accordion" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                <h5 class="fw-bold"> TECNOLOGÍAS DE LA ENERGÍA, SOFTWARE Y LAS TELECOMUNICACIONES</h5>
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample">


                            <div class="accordion-body p-0 pt-4">


                                <table class="table py-0">
                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center fw-bold">OBJETIVOS</p>

                                            <ul>
                                                <li><b>General:</b> Promover el desarrollo y difusión de
                                                    mejoras
                                                    tecnológicas a partir del conocimiento interdisciplinario que propone el
                                                    desarrollo de las industrias 4.0 en la solución innovadora de problemas
                                                    presentados en los sectores productivos de la región.</li>
                                                <br>
                                                <li><b>Específicos:</b>
                                                    <ul>
                                                        <li>Proponer el alcance de productos de investigación de
                                                            impacto asociados a la generación de contenidos físicos y
                                                            digitales como
                                                            parte de los resultados de los proyectos de investigación
                                                            aplicada y
                                                            desarrollo tecnológico.</li>
                                                        <li>Difundir los conocimientos tecnológicos, a partir de
                                                            la divulgación tecnológica, para su integración en el sector
                                                            productivo
                                                            y empresarial como también en la actualización de los procesos
                                                            formativos del centro de formación.</li>
                                                        <li>Fortalecer la generación de prototipos que aporten al
                                                            mejoramiento de competitividad y productividad de un sector o
                                                            varios
                                                            sectores productivos de la ciudad o región.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center fw-bold">ÁREAS DEL CENTRO DE FORMACIÓN
                                                QUE
                                                INCLUYE</p>

                                            <ul>

                                                <li>Electricidad</li>
                                                <li>Automatización y Electrónica</li>
                                                <li>Infraestructura de TIC</li>
                                                <li>Software e Industrias Creativas</li>

                                            </ul>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center fw-bold">TEMAS DE INVESTIGACIÓN</p>

                                            <ul>

                                                <li class="texto-inv">Domótica e Inmótica </li>
                                                <li class="texto-inv">Robótica</li>
                                                <li class="texto-inv">Diseño Mecatrónico Transdisciplinario</li>
                                                <li class="texto-inv">Agricultura de Precisión</li>
                                                <li class="texto-inv">Inteligencia Artificial</li>
                                                <li class="texto-inv">Hardware Reprogramable</li>
                                                <li class="texto-inv">Diseño e Ingeniería</li>
                                                <li class="texto-inv">Calidad de Energía Eléctrica</li>
                                                <li class="texto-inv">Energías Renovables</li>
                                                <li class="texto-inv">Eficiencia Energética</li>
                                                <li class="texto-inv">Instrumentación Industrial</li>
                                                <li class="texto-inv">Automatización</li>
                                                <li class="texto-inv">Mecánica Aplicada</li>
                                                <li class="texto-inv">Diseño 3D</li>
                                                <li class="texto-inv">Infraestructura Tecnológica</li>
                                                <li class="texto-inv">Desarrollo de Software</li>
                                                <li class="texto-inv">Producción de Medios Audiovisuales Digitales</li>
                                                <li class="texto-inv">Grabación y Producción de Sonido</li>
                                                <li class="texto-inv">Software CAD, CAM, CAE</li>
                                                <li class="texto-inv">Tecnologías CLOUD</li>
                                                <li class="texto-inv">Ciberseguridad</li>
                                                <li class="texto-inv">Internet de las Cosas (IoT)</li>
                                                <li class="texto-inv">Analítica de Datos</li>
                                                <li class="texto-inv">DevOps</li>
                                                <li class="texto-inv">Big data</li>

                                            </ul>
                                        </td>
                                    </tr>



                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center fw-bold">SEMILLEROS DE INVESTIGACIÓN
                                                ASOCIADOS
                                            </p>

                                            <ul>
                                                <li><b>SENAUTRONIC</b>-Semillero en Electrónica, Automatización y
                                                    Mecatrónica del SENA</li>
                                                <li><b>EREECA</b>-Semillero de Investigación en Energías Renovables,
                                                    Eficiencia Energética y Calidad de Energía</li>
                                                <li><b>INNOVATELCOS</b>-Semillero de Investigación en Innovación Tecnológica
                                                    de las Telecomunicaciones</li>
                                                <li><b>CREAD+</b> -Semillero de Investigación de Creativas y ADSI</li>
                                            </ul>
                                        </td>
                                    </tr>


                                </table>



                            </div>
                        </div>
                    </div>



                    <!-- ACORDEON 2 ACORDEON 2 ACORDEON 2 ACORDEON 2 ACORDEON 2 ACORDEON 2 ACORDEON 2 ACORDEON 2 -->



                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed bg-accordion" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                <h5 class="fw-bold">INNOVACIÓN TECNOLÓGICA Y SOSTENIBILIDAD PARA LA INDUSTRIA</h5>
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body p-0 pt-4">


                                <table class="table py-0">
                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center  fw-bold">OBJETIVOS</p>

                                            <ul>
                                                <li><b>General:</b> Promover el desarrollo y difusión de mejoras
                                                    tecnológicas a partir del conocimiento interdisciplinario en la
                                                    investigación aplicada y el desarrollo tecnológico como soluciones
                                                    innovadoras en la optimización de procesos productivos a partir de
                                                    materiales innovadores de bajo impacto ambiental para el mejoramiento de
                                                    condiciones de vida de la población partir de residuos industriales en
                                                    el departamento del Cauca.</li>
                                                <br>
                                                <li><b>Específicos:</b>
                                                    <ul>
                                                        <li>Estructurar productos de investigación de impacto asociados a la
                                                            generación de contenidos físicos y digitales como parte de los
                                                            resultados de los proyectos de investigación aplicada y
                                                            desarrollo tecnológico.</li>
                                                        <li>Realizar la difusión de conocimientos tecnológicos en los que
                                                            las comunidades y empresas participar como actores activos en la
                                                            apropiación de conocimiento como parte de los resultados de los
                                                            proyectos de investigación y desarrollo tecnológico.</li>
                                                        <li>Buscar la participación de empresas en proyectos de I+D+i para
                                                            la generación de conocimiento técnico y tecnológico, focalizado
                                                            en temas ambientales, que aporte al mejoramiento de
                                                            competitividad y productividad de un sector o varios sectores
                                                            productivos de la ciudad o región.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center fw-bold">ÁREAS DEL CENTRO DE FORMACIÓN
                                                QUE
                                                INCLUYE</p>

                                            <ul>

                                                <li>Mecánica</li>
                                                <li>Ambiental</li>
                                                <li>Construcción</li>
                                                <li>Transformación (Joyería, Soldadura, Ebanistería, Marroquinería,
                                                    Confecciones, Metalmecánica)</li>

                                            </ul>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center fw-bold">TEMAS DE INVESTIGACIÓN</p>

                                            <ul>

                                                <li class="texto-inv">Generación de Biocombustibles</li>
                                                <li class="texto-inv">Tratamiento y Transformación de Residuos Sólidos</li>
                                                <li class="texto-inv">Generación de Biogás</li>
                                                <li class="texto-inv">Gestión Ambiental</li>
                                                <li class="texto-inv">Vehículos Eléctricos-Híbridos</li>
                                                <li class="texto-inv">Biorremediación</li>
                                                <li class="texto-inv">Materiales para la Industria</li>
                                                <li class="texto-inv">Tratamiento de Agua</li>
                                                <li class="texto-inv">Mecánica Computacional</li>
                                                <li class="texto-inv">Construcción de Vehículo Prototipo a Propulsión
                                                    Eléctrica</li>
                                                <li class="texto-inv">Técnicas de Innovación de Materiales de Construcción
                                                </li>

                                            </ul>
                                        </td>
                                    </tr>



                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center fw-bold">SEMILLEROS DE INVESTIGACIÓN
                                                ASOCIADOS
                                            </p>

                                            <ul>
                                                <li><b>SIITIS</b>-Semillero de Investigación de Innovación
                                                    Tecnológica de la Industria y Sostenibilidad</li>

                                            </ul>
                                        </td>
                                    </tr>


                                </table>


                                <!-- Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body. -->
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed bg-accordion" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree" aria-expanded="false"
                                aria-controls="flush-collapseThree">
                                <h5 class="fw-bold">ACTIVIDAD FÍSICA, RECREACIÓN Y DEPORTES</h5>
                            </button>
                        </h2>
                        <div id="flush-collapseThree" class="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body p-0 pt-4">


                                <table class="table py-0">
                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center  fw-bold">OBJETIVOS</p>

                                            <ul>
                                                <li><b>General:</b> Promover el diseño, ejecución y construcción de
                                                    proyectos de investigación que aporten al conocimiento científico desde
                                                    el deporte y la actividad física para su divulgación en los sectores
                                                    académicos y deportivos del Departamento del Cauca y la región.</li>
                                                <br>
                                                <li><b>Específicos:</b>
                                                    <ul>
                                                        <li>Proponer el alcance de productos de investigación de impacto
                                                            asociados a la generación de contenidos físicos y digitales como
                                                            parte de los resultados de los proyectos de investigación
                                                            aplicada y desarrollo tecnológico.</li>
                                                        <li>Difusión de conocimientos tecnológicos a partir de la
                                                            divulgación tecnológica para su integración en el sector
                                                            productivo y empresarial como también en la actualización de los
                                                            procesos formativos del centro de formación.</li>
                                                        <li>Fortalecer la generación de proyectos vinculados al sector
                                                            productivo e institucional que busquen el mejoramiento de
                                                            competitividad y productividad de un sector o varios sectores
                                                            productivos de la ciudad o región.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center fw-bold">ÁREAS DEL CENTRO DE FORMACIÓN
                                                QUE INCLUYE</p>

                                            <ul>

                                                <li>Actividad Física -Entrenamiento Deportivo</li>


                                            </ul>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center fw-bold">TEMAS DE INVESTIGACIÓN</p>

                                            <ul>

                                                <li class="texto-inv">Actividad Física</li>
                                                <li class="texto-inv">Fisiología del Ejercicio</li>
                                                <li class="texto-inv">Valoración Cineantropométrica y de las Capacidades
                                                    Físicas del
                                                    Deportista</li>
                                                <li class="texto-inv">Valoración Ergonómica y Biomecánica</li>
                                                <li class="texto-inv">Estrategias Pedagógicas y Metodológicas para Mejorar
                                                    la
                                                    Calidad del Servicio</li>
                                                <li class="texto-inv">Entrenamiento</li>
                                                <li class="texto-inv">Fisiología del Ejercicio</li>
                                                <li class="texto-inv">Valoración Biomecánica de los Gestos Técnicos</li>
                                                <li class="texto-inv">Enseñanza en el Rendimiento Físico, Técnico y Táctico
                                                    del
                                                    Deportista</li>
                                                <li class="texto-inv">Influencia de Métodos y Medios Innovadores de
                                                    Entrenamiento
                                                </li>

                                            </ul>
                                        </td>
                                    </tr>



                                    <tr>
                                        <td>
                                            <p class="d-flex justify-content-center fw-bold">SEMILLEROS DE INVESTIGACIÓN
                                                ASOCIADOS
                                            </p>

                                            <ul>
                                                <li><b>SINAFRED</b>-Semillero de Investigación en Actividad
                                                    Física, Recreación y Deporte</li>

                                            </ul>
                                        </td>
                                    </tr>


                                </table>


                                <!-- Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body. -->
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>

    </div>
</template>


<script>
export default {

}
</script>

<style scoped>
.accordion-body {

    background-color: rgba(226, 226, 226, 0.577);
}


.accordion-button:hover {
    color: rgb(0, 110, 9);
}

.accordion-button:active {
    color: rgb(0, 110, 9);
}

.bg-accordion {

    background-color: rgb(206, 206, 206);
}


th,
th:after,
th:hover {
    background-color: #5572ad;
    color: #f2f2f2;

}


.logoinnovatec {

    max-width: 100%;
    max-height: 100%;
}

.parrafo-inicial {
    text-align: justify;
    background-color: #c5c5c589;
}







#parallax-world-of-ugg h2 {
    font-family: 'Work Sans';
    font-size: 70px;
    letter-spacing: 10px;
    text-align: center;
    color: rgb(255, 255, 255);
    font-weight: 400;
    text-transform: uppercase;
    z-index: 10;
    opacity: 1;
}




#parallax-world-of-ugg .parallax-two {
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: hidden;
    position:static;
    width: 100%;
    background-image: url(https://vozdelosandes.com/wp-content/uploads/2019/09/investigacion-sena.jpeg);
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

/* Extras */
/**************************/


@media screen and (max-width: 1920px) {
    #parallax-world-of-ugg .title {
        padding: 110px;

    }


    @media screen and (max-width: 1700px) and (min-width: 768px) {

        #parallax-world-of-ugg .title {
            background: rgb(255, 255, 255);
            padding: 50px;
            margin: 0 auto;
            text-align: center;
        }
    }



    #parallax-world-of-ugg .block {
        padding: 40px;
        width: 620px;
    }
}

@media screen and (max-width: 767px) {
    #parallax-world-of-ugg .block {
        padding: 30px;
        width: 420px;
    }

    #parallax-world-of-ugg h2 {
        font-size: 30px;
    }

    #parallax-world-of-ugg .block {
        padding: 30px;
    }

    #parallax-world-of-ugg .parallax-one,
    #parallax-world-of-ugg .parallax-two,
    #parallax-world-of-ugg .parallax-three {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}
</style>