<template>

    <PageNotFoundComponent></PageNotFoundComponent>

</template>




<script>
import PageNotFoundComponent from '@/components/PageNotFoundComponent.vue'



export default {
    name: 'PageNotFoundView',
    components: {
        PageNotFoundComponent

    }
}
</script>

<style></style>